import React, { useRef, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userLogOut } from "../store/feature/userSlice";
import PathRoute from "../router/PathRoute";

const Navbars = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [MenuOpenBg, setMenuOpenBg] = useState(false);
  const { isLogged } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const navLinksRef = useRef(null);

  const onToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setMenuOpenBg(!MenuOpenBg);
  };
  const onToggleMenulist = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const userLogout = async () => {
    try {
      await dispatch(userLogOut());
      // Redirect to root page after successful logout
    } catch (error) {
      console.error("Logout failed:", error);
      // Handle error as needed
    }
  };

  const openLogin = () => {
    window.location.href = "https://admin.bharatparcel.org/";
  };

  return (
    <div
      className={`w-full fixed z-40 ${
        MenuOpenBg ? "bg-blue-600 sm:bg-none" : "bg-gradient-to-b from-blue-800"}`}
    >
      <nav className="grid grid-cols-8 justify-items-center items-center gap-4 p-6">
        <div className="col-span-2">
          <h1 className="text-3xl font-bold text-white md:hidden ">BPS</h1>
        </div>
        <div className="col-span-4">
          <div
            ref={navLinksRef}
            className={`nav-links duration-500 md:static absolute ${
              isMenuOpen ? "top-20" : "top-[-500%]"
            } bg-blue-600 md:bg-transparent md:min-h-fit min-h-[50vh] left-0 md:w-auto w-full flex items-center px-5`}
          >
            <ul
              onClick={onToggleMenulist}
              className="flex md:flex-row flex-col md:items-center md:gap-[4vw] gap-8 text-xl font-semibold"
            >
              <Link to="/" className="hover:border-b-4">
                <li className="text-white text-3xl font-bold px-4 hidden md:block">BPS</li>
              </Link>
              <Link to="/" className="hover:border-b-4">
                <li className="text-white px-4">Home</li>
              </Link>
              <Link to="/aboutus" className="hover:border-b-4 w-36 md:text-center">
                <li className="text-white px-4  ">About Us</li>
              </Link>
              <Link to="/ourservice" className="hover:border-b-4">
                <li className="text-white px-4">Services</li>
              </Link>
              <Link to="/contactus" className="hover:border-b-4 w-36 md:text-center">
                <li className="text-white px-4  style={{ whiteSpace: 'nowrap' }}">
                  Contact Us
                </li>
              </Link>
              <Link to={PathRoute.BookOrderForm} className="hover:border-b-4 w-36 md:text-center">
                <li className="text-white px-4  style={{ whiteSpace: 'nowrap' }}">
                  Order
                </li>
              </Link>
              {isLogged ? (
                <div
                  onClick={userLogout}
                  className="text-lg text-center bg-white text-blue-600 px-4  p-1 rounded-lg cursor-pointer "
                >
                  Log out
                </div>
              ) : (
                <div
                  onClick={openLogin}
                  className="text-lg text-center bg-white text-blue-600 px-4 p-1 w-28 rounded-lg cursor-pointer my-4 "
                >
                  Log in
                </div>
              )}
            </ul>
          </div>
        </div>

        <div className="nav-links col-span-1 flex md:hidden">
          <button
            className="text-lg bg-white text-blue-600 rounded-lg p-2 "
            onClick={onToggleMenu}
          >
            {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
          </button>
        </div>
      </nav>
    </div>
  );
};

export default Navbars;
