
const InputError = ({error, touched, className}) => {
    
  return (
    <>
    <div className='absolute'>
      {error && touched ? (
        <p className='text-sm text-red-500 '>
          {error}
        </p>
      ) : null}
    </div>
    {/* <div className={`${className}`}>
      {error && touched ? (
        <div className=' bg-red-200 shadow-md shadow-red-500 text-sm w-full p-2 fixed top-8 right-0'>Something went Wrong...</div>
      ) : null}
      </div> */}
    </>
  )
}

export default InputError;
