import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { getAxiosWithOutToken } from "../../axios/AxiosObj";
import CloseIcon from "@mui/icons-material/Close";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
} from "../../function/utilies";
import InputError from "../../component/props/InputError";
import StateName from "../../component/dropdown/StateName";
import CityName from "../../component/dropdown/CityName";
import { PrimeButton } from "../../component/props/PrimeButton";
import { useDispatch, useSelector } from "react-redux";
import { setupdateState } from "../../store/feature/userSlice";

const initialValues = {
  firstName: "",
  middleName: "",
  lastName: "",
  mobileNo: "",
  email: "",
  address1: "",
  district: "",
  pincode: "",
  idProofNo: "",
  idProofDoc: "",
  userPhoto: "",
  state: "",
  city: "",
};

const AddCustomerForm = ({ handleForm }) => {
  const navigate = useNavigate();
  const { updateState } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  console.log("updateState", updateState);
  const ConstomerCreateSchema = Yup.object({
    firstName: Yup.string().required("First name should not be empty"),
    // middleName: Yup.string().required("Middle name should not be empty"), // Uncomment if needed
    lastName: Yup.string().required("Last name should not be empty"),
    mobileNo: Yup.number().required("Mobile number should not be empty"),
    email: Yup.string().required("Email should not be empty"),
    address1: Yup.string().required("Address should not be empty"),
    state: Yup.string().required("State should not be empty"),
    city: Yup.string().required("City should not be empty"),
    district: Yup.string().required("District should not be empty"),
    pincode: Yup.number().required("Pin code should not be empty"),
    idProofNo: Yup.number().required("ID number should not be empty"),
    idProofDoc: Yup.string().required("ID document should not be empty"),
    userPhoto: Yup.string().required("Customer's photo should not be empty"),
  });

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: initialValues,
      validationSchema: ConstomerCreateSchema,

      onSubmit: async (values, action) => {
        console.log("onSubmit API working");
        let data = new FormData();
        data.append("firstName", values.firstName);
        data.append("middleName", values.middleName);
        data.append("lastName", values.lastName);
        data.append("mobile", values.mobileNo);
        data.append("email", values.email);
        data.append("address1", values.address1);
        data.append("district", values.district);
        data.append("state", values.state);
        data.append("city", values.city);
        data.append("pincode", values.pincode);
        // data.append("password", values.pincode);
        data.append("idProofNo", values.idProofNo);
        if (photo) {
          data.append("userPhoto", photo); //userPhoto
        }
        if (IdDoc) {
          data.append("idProofDoc", IdDoc);
        }
        // Log the form data entries
        for (let [key, value] of data.entries()) {
          console.log(`${key}: ${value}`);
        }
        try {
          const response = await getAxiosWithOutToken({
            method: "POST",
            url: "customer/add",
            data: data,
            reqType: "formData",
          });
          // API response 200 201
          if (response.data.success) {
            alertResponseMsgSuccess(response.data.msg);
            action.resetForm();
            handleForm();
            navigate("/bookorderform");
            //--------- update customer list -------------------------------
            dispatch(setupdateState(!updateState));
          } else {
            alertResponseMsgError(response.data.msg);
          }
        } catch (error) {
          console.error(error);
          alertResponseMsgError(error.response.data.msg);
          // alertResponseMsgError(error.response.msg);
        }
      },
    });

  //----------------------------------------------- upload Document -------------------------------------------//
  const [photo, setPhoto] = useState(null);
  // const [PhotoErr, setPhotoErr] = useState(null);
  const [photoPerview, setPhotoPerview] = useState(null);
  const [IdDoc, setIdDoc] = useState(null);
  // const [PhotoErr, setPhotoErr] = useState(null);
  const [idDocPerview, setIdDocPerview] = useState(null);

  const handleChangeFile = (e) => {
    const { name, files } = e.target;
    if (name === "userPhoto") {
      let userImage = files[0];
      if (userImage.size > 2000000) {
        // alertResponseMsgError("Image Should be less then 2 MB");
        return false;
      }
      if (
        userImage.type.includes("image/jpeg") ||
        userImage.type.includes("image/jpg") ||
        userImage.type.includes("image/png")
      ) {
        const imageUrl = URL.createObjectURL(userImage);
        setPhotoPerview(imageUrl);
        setPhoto(userImage);
        setFieldValue("userPhoto", userImage); // Update Formik state
      } else {
        // alertResponseMsgError("Image formate Should be jpeg, jpg, png");
      }
    }
    if (name === "idProofDoc") {
      let IDProof = files[0];
      if (IDProof.size > 2000000) {
        console.log("Error Size");
        // alertResponseMsgError("Image Should be less then 2 mb");
        return false;
      }
      if (
        IDProof.type.includes("image/jpeg") ||
        IDProof.type.includes("image/jpg") ||
        IDProof.type.includes("image/png")
      ) {
        const imageUrl = URL.createObjectURL(IDProof);
        setIdDocPerview(imageUrl);
        setIdDoc(IDProof);
        setFieldValue("idProofDoc", IDProof); // Update Formik state
      } else {
        // alertResponseMsgError("Image formate Should be jpeg, jpg, png");
      }
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center  bg-black bg-opacity-50 z-50 overflow-y-auto ">
      {/* <BackButton btnName="Constomer Details" /> */}
      <div
        onClick={(e) => e.stopPropagation()}
        className="absolute bg-white p-6 rounded-lg shadow-lg w-2/3 top-5"
      >
        <div className="flex justify-end mb-4">
          <button onClick={handleForm}>
            <CloseIcon />
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="md:grid grid-cols-6 gap-4 text-left text-mainTxtColor">
            <div className="col-span-2 gap-6 lg:flex py-2 items-center">
              <label
                htmlFor="firstName"
                className=" w-36 md:flex-none text-sm font-medium"
              >
                First Name
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="firstName"
                  value={"" || values.firstName}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2  "
                  placeholder="First Name"
                />
                <InputError
                  error={errors.firstName}
                  touched={touched.firstName}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 lg:flex py-2 items-center">
              <label
                htmlFor="middleName"
                className=" w-36 md:flex-none  text-sm font-medium"
              >
                Middle Name
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="middleName"
                  value={"" || values.middleName}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 outline-none "
                  placeholder="Middle Name"
                />
                <InputError
                  error={errors.middleName}
                  touched={touched.middleName}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 lg:flex py-2 items-center">
              <label
                htmlFor="lastName"
                className=" w-36 md:flex-none text-sm font-medium"
              >
                Last Name
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="lastName"
                  value={"" || values.lastName}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="Last Name"
                />
                <InputError
                  error={errors.lastName}
                  touched={touched.lastName}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6  lg:flex py-2  items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                Contact Number
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="mobileNo"
                  value={"" || values.mobileNo}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Contact"
                />
                <InputError
                  error={errors.mobileNo}
                  touched={touched.mobileNo}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6 lg:flex py-2 items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                Email ID
              </label>
              <div className="w-full">
                <input
                  type="email"
                  name="email"
                  value={"" || values.email}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Email"
                />
                <InputError error={errors.email} touched={touched.email} />
              </div>
            </div>
            <div className="div col-span-6 font-semibold text-left text-lg">
              Addresss
            </div>
            <div className="col-span-6 gap-6  lg:flex py-2  items-center">
              <label className="w-36 md:flex-none text-sm font-medium ">
                Address / Street
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="address1"
                  value={"" || values.address1}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Address / Street"
                />
                <InputError
                  error={errors.address1}
                  touched={touched.address1}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6  lg:flex py-2 items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                State
              </label>
              <div className="w-full">
                <StateName
                  handleChange={handleChange}
                  name={"state"}
                  value={values.state}
                />
                <InputError error={errors.state} touched={touched.state} />
              </div>
            </div>
            <div className="col-span-3 gap-6  lg:flex py-2 items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                City
              </label>
              <div className="w-full">
                <CityName
                  handleChange={handleChange}
                  name={"city"}
                  value={values.city}
                  stateId={values.state}
                />
                <InputError error={errors.city} touched={touched.city} />
              </div>
            </div>
            <div className="col-span-3 gap-6  lg:flex py-2  items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                District
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="district"
                  value={"" || values.district}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="District"
                />
                <InputError
                  error={errors.district}
                  touched={touched.district}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6  lg:flex py-2  items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                Pin Code
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="pincode"
                  value={"" || values.pincode}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Pin Code"
                />
                <InputError error={errors.pincode} touched={touched.pincode} />
              </div>
            </div>
            <div className=" col-span-6 font-semibold text-left text-lg">
              Documents
            </div>
            <div className="col-span-3 gap-6  lg:flex py-2  items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                ID Proof
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="idProofNo"
                  value={"" || values.idProofNo}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Id Proof No."
                />
                <InputError
                  error={errors.idProofNo}
                  touched={touched.idProofNo}
                />
              </div>
              <div className="w-full p-2 bg-white rounded-inputBRedius hover:bg-blue-100">
                <input
                  type="file"
                  name="idProofDoc"
                  onChange={handleChangeFile}
                  className="w-full file:bg-white
                file:rounded file:border-0
                 file:font-semibold
                file:text-primeryBgColor"
                  placeholder="Id Proof"
                  accept="image/png, image/jpeg, image/jpg"
                />
                <InputError
                  error={errors.idProofDoc}
                  touched={touched.idProofDoc}
                />
                {idDocPerview ? (
                  <img src={idDocPerview} alt="Id Proof" />
                ) : null}
              </div>
            </div>
            <div className="col-span-3 gap-6  lg:flex py-2  items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                Upload Constomer's Photo
              </label>
              <div className="w-full p-2 bg-white rounded-inputBRedius hover:bg-blue-100">
                <input
                  type="file"
                  name="userPhoto"
                  onChange={handleChangeFile}
                  className="w-full file:bg-white
                file:rounded file:border-0
                 file:font-semibold
                file:text-primeryBgColor"
                  placeholder="Customer's Photo"
                  accept="image/png, image/jpeg, image/jpg"
                />
                <InputError
                  error={errors.userPhoto}
                  touched={touched.userPhoto}
                />
                {photoPerview ? <img src={photoPerview} alt="dd" /> : null}
              </div>
            </div>
          </div>
          <div className="mt-12">
            <div onClick={handleSubmit}>
              <PrimeButton btnName="Submit" btnType="button" />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCustomerForm;
