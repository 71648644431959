import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";
import Dashboard from "../component/Dashboard";
import Navbar from "../component/Navbar";
import AboutUs from "../page/AboutUs";
import Footer from "../component/Footer";
import OurService from "../page/OurService";
import ContactUs from "../page/ContactUs";
import Registration from "../authentication/Registration";
import OurMission from "../page/ourPages/OurMission";
import PathRoute from "./PathRoute";
import OurApproch from "../page/ourPages/OurApproch";
import OurVision from "../page/ourPages/OurVision";
import BookOrderForm from "../page/order/BookOrderForm";
// import LoginPage from "../authentication/LoginPage";
// import { useSelector } from "react-redux";

const AppRoute = () => {

  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path={PathRoute.AboutUs} element={<AboutUs />} />
        <Route path={PathRoute.OurService} element={<OurService />} />
        <Route path={PathRoute.ContactUs} element={<ContactUs />} />
        <Route path={PathRoute.Registration} element={<Registration />} />
        <Route path={PathRoute.OurMission} element={<OurMission/>} />
        <Route path={PathRoute.OurApproch} element={<OurApproch/>} />
        <Route path={PathRoute.OurVision} element={<OurVision/>} />
        <Route path={PathRoute.BookOrderForm} element={<BookOrderForm/>} />

      </Routes>
      <Footer />
    </Router>
  );
};

export default AppRoute;
