import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import {
//   alertResponseMsgError,
//   alertResponseMsgSuccess,
// } from "../../function/utilies";
import { getAxiosWithOutToken } from "../../axios/AxiosObj";
import secureLocalStorage from "react-secure-storage";

// Default Const values
const initialAccessToken = secureLocalStorage.getItem("access") || null;
const initialIsLoggedIn =
  secureLocalStorage.getItem("isLIn") === true ? true : false;
const initialRole = secureLocalStorage.getItem("role") || null;

// Define an asynchronous thunk for user login
export const userLogin = createAsyncThunk(
  "user/userLogin",
  async ({ username, password }) => {
    try {
      const formData = new FormData();
      formData.append("password", password);
      formData.append("username", username);

      const response = await getAxiosWithOutToken({
        method: "POST",
        url: "user/login",
        data: formData,
      });
      return response.data;
    } catch (error) {
    //   alertResponseMsgError(
    //     error?.response?.data?.msg || "Invalid credentials"
    //   );
    }
  }
);

export const userLogOut = createAsyncThunk("user/userLogOut", async () => {
  try {
    // Clear local storage and reload the page on logout
    localStorage.clear();
    secureLocalStorage.clear();
    window.location.reload();
  } catch (error) {
    // Handle error or dispatch error-related actions here
    console.log(error);
  }
});
//-------------------------------------------------------active user profile data ----------------------------------//

export const userSlice = createSlice({
  name: "user",
  initialState: {
    // for show login page use isLogged:initialIsLoggedIn
    isLogged: initialIsLoggedIn,
    role: initialRole,
    accesstoken: initialAccessToken,
    updateState: false,

    loading: false, // Added loading state to handle UI loading indicators
  },
  reducers: {
    setAccessToken: (state, action) => {
      state.accesstoken = action.payload;
    },
    setIsLoggedIn: (state, action) => {
      state.isLogged = action.payload;
    },
    logoutUser: (state) => {
      state.isLogged = false;
      state.role = null;
      state.accesstoken = null;
    },
    
    setsupplierDetails: (state, action) => {
      state.supplierDetails = action.payload;
      secureLocalStorage.setItem("supplierDetails", action.payload);
    },
    setupdateState: (state, action) => {
      state.updateState = action.payload;
    },
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        if (action.payload && action.payload.role && action.payload.token) {
          state.loading = false;
          state.isLogged = true;
          state.role = action.payload.role;
          secureLocalStorage.setItem("isLIn", true);
          secureLocalStorage.setItem("access", action.payload.token);
          secureLocalStorage.setItem("role", action.payload.role);
        //   alertResponseMsgSuccess("Login Success.");
        }
      })
      .addCase(userLogin.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {
  setAccessToken,
  setIsLoggedIn,
  logoutUser,
  setupdateState
 
} = userSlice.actions;

export const selectUser = (state) => state.user;

export default userSlice.reducer;