import React, { useEffect, useState } from "react";
import { getAxiosWithOutToken } from "../../axios/AxiosObj";

const Dropdown = ({ label, name, value, SetStationId }) => {
  const [allStationRaw, setAllStationRaw] = useState([]);
  const fetchStationData = async () => {
    try {
      const response = await getAxiosWithOutToken({
        method: "get",
        url: "station/all",
      });

      if (response?.data?.data) {
        setAllStationRaw(response.data.data);
      } else {
        console.error("Invalid response structure:", response);
      }
    } catch (error) {
      console.error("Failed to fetch station data:", error);
    }
  };

  useEffect(() => {
    fetchStationData();
  }, []);

  const handleSelectChange = (event) => {
    const selectedStationId = event.target.value; // Get the selected station ID
    SetStationId(selectedStationId); // Send the selected station ID to the parent component
  };

  return (
    <div>
      <select
        value={value}
        name={name}
        onChange={handleSelectChange} // Handle change on select
        className="w-full rounded-inputBRedius bg-inputBgColor p-2"
      >
        <option value="">Select {label}</option>
        {allStationRaw?.map((station) => (
          <option key={station.stationId} value={station.stationId}>
            {station.stationName}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
