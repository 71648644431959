import React from "react";
import mail from "../image/mail.svg";
import location from "../image/location.svg";
import phone from "../image/phone.svg";
import yt from "../image/yt.svg";
import fb from "../image/fb.svg";
import ig from "../image/ig.svg";
import tw from "../image/tw.svg";

export default function Contact() {
  return (
    <>
      <div className="bg-blue-100 bg-bgElement bg-no-repeat p-7 lg:p-20 ">
        <div className="bg-gradient-to-b from-blue-200 rounded-3xl lg:p-18 p-4">
          <div className=" text-center md:p-4 ">
            <h1 className="text-4xl sm:text-6xl font-bold font-san my-4  ">
              Fill the form to contact us
            </h1>
            <p className="py-4 font-semibold">
              Complete the form for direct contact. Your inquiries matter to us.{" "}
              <br /> Elevate your experience with our dedicated team—your
              partner in seamless logistics and unparalleled service. Connect
              with confidence.
            </p>
          </div>
          <div className=" grid grid-cols-1 lg:grid-cols-3 place-items-start gap-5  sm:gap-10 sm:p-8  my-8">
            <div className="col-span-1 bg-blue-800 rounded-2xl p-4 ">
              <div className="p-2 sm:p-5 ">
                <h1 className="text-xl text-white font-bold">
                  Contact Information
                </h1>
                <p className="my-4 text-white text-sm sm:text-base">
                  Your Delivery Partner. Trustworthy Connections Await.
                </p>
                <div className="flex justify-start">
                  <img src={mail} alt="logo" className="p-1" />
                  <p className="p-1 text-white text-sm sm:text-base">
                    caarun@rediffmail.com
                  </p>
                </div>
                <div className="flex justify-start">
                  <img src={location} alt="logo" className="p-1" />
                  <p className="p-1 text-white text-sm sm:text-base">
                    332, KUCHA GHASI RAM, FATEH PURI, CHANDNI CHOWK DELHI DL
                    110006
                  </p>
                </div>
                <div className="flex justify-start">
                  <img src={phone} alt="logo" className="p-1" />
                  <p className="p-1 text-white text-sm sm:text-base">
                    +91 7017866149
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-4 p-4">
                <a href="/">
                  <img
                    src={yt}
                    alt="logo"
                    className="p-1 border-2 hover:border-green-600 hover:bg-green-500 rounded-lg "
                  />
                </a>
                <a href="/">
                  <img
                    src={tw}
                    alt="logo"
                    className="p-1 border-2 hover:border-green-600 hover:bg-green-500 rounded-lg "
                  />
                </a>
                <a href="/">
                  <img
                    src={ig}
                    alt="logo"
                    className="p-1 border-2 hover:border-green-600 hover:bg-green-500 rounded-lg "
                  />
                </a>
                <a href="/">
                  <img
                    src={fb}
                    alt="logo"
                    className="p-1 border-2 hover:border-green-600 hover:bg-green-500 rounded-lg "
                  />
                </a>
              </div>
            </div>
            <div className=" w-full col-span-2 card rounded-2xl p-4 sm:p-9  ">
              <form>
                <div className="mb-2">
                  <span className="block text-lg font-bold text-slate-700 p-2">
                    Full Name
                  </span>
                  <input
                    type="text"
                    placeholder="Enter your full name"
                    className=" w-full  rounded-xl"
                  />
                </div>
                <div className="mb-2">
                  <span className="block text-lg font-bold text-slate-700 p-2">
                    Email
                  </span>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    className="peer ... w-full  rounded-xl"
                  />
                  <p className="mt-2 invisible peer-invalid:visible text-red-600 text-sm">
                    Please provide a valid email address.
                  </p>
                </div>
                <div className="mb-2">
                  <span className="block text-lg font-bold text-slate-700 p-2">
                    Message
                  </span>
                  <textarea
                    name="fasdfsdf"
                    id=""
                    cols="30"
                    rows="5"
                    className=" w-full  rounded-xl"
                  ></textarea>
                </div>
                <button className="bg-green-600 hover:bg-green-800 text-center text-lg font-bold text-white p-3 w-full rounded-xl">
                  Get Strated
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
